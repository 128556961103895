import { Box, useColorModeValue } from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import type { VisibilityValue } from 'modules/sharing/utils'

type ShareIconProps = {
  visibility: VisibilityValue
  isFromOtherOrg: boolean
}

export const ShareIcon = ({ visibility, isFromOtherOrg }: ShareIconProps) => {
  const circleColor = useColorModeValue(
    'white',
    'var(--chakra-colors-gray-900)'
  )
  return (
    <>
      {isFromOtherOrg && <FontAwesomeIcon icon={regular('passport')} />}
      <Box as="span" className="fa-layers fa-fw">
        <FontAwesomeIcon icon={visibility.primaryIcon} />
        {visibility.secondaryIcon && (
          <>
            <FontAwesomeIcon
              icon={solid('circle')}
              color={circleColor}
              transform="down-6 right-6"
            />
            <FontAwesomeIcon
              icon={visibility.secondaryIcon}
              transform="shrink-7 right-6 down-6"
            />
          </>
        )}
      </Box>
    </>
  )
}
