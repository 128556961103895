import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'

import { Permission } from 'modules/api'

export enum NoAccessPermission {
  'NoAccess' = 'no_access',
}

export interface PermissionsMenuProps {
  isDisabled?: boolean
  options: Array<Permission | NoAccessPermission>
  disabledOptions?: Array<{
    permission: Permission | NoAccessPermission
    reason: string
  }>
  selected: Permission | NoAccessPermission
  variant?: string
  onClick?: (option: Permission | NoAccessPermission) => void
  onRemove?: () => void
}

type HumanReadablePermission = {
  title: () => string
  description: () => string
}
export const PermissionMapToHumanReadable: Record<
  Permission | NoAccessPermission.NoAccess,
  HumanReadablePermission
> = {
  [Permission.Manage]: {
    title: () =>
      t({
        message: 'Full Access',
        comment:
          "'Full Access' refers to someone who has the highest level of permissions on a doc. Please use the same phrase to refer to 'Full Access' across the app.",
      }),
    description: () => t`Can view, comment, edit, and share with others.`,
  },
  [Permission.Edit]: {
    title: () => t`Edit`,
    description: () => t`Can view, comment, edit, but not share with others.`,
  },
  [Permission.Comment]: {
    title: () => t`Comment`,
    description: () => t`Can view and comment, but not edit.`,
  },
  [Permission.View]: {
    title: () => t`View`,
    description: () => t`Can view, but not comment or edit.`,
  },
  [NoAccessPermission.NoAccess]: {
    title: () => t`No access`,
    description: () => t`Cannot view, comment, or edit.`,
  },
}

export const PermissionsMenu = ({
  isDisabled,
  options,
  disabledOptions = [],
  selected,
  variant = 'ghost',
  onClick = () => {},
  onRemove,
}: PermissionsMenuProps) => {
  return (
    <Menu closeOnSelect={true}>
      <MenuButton
        data-testid={`permission-menu-value-${selected}`}
        as={Button}
        display="flex"
        size="sm"
        isDisabled={isDisabled}
        variant={variant}
        rightIcon={<FontAwesomeIcon icon={regular('chevron-down')} />}
      >
        {PermissionMapToHumanReadable[
          selected ? selected : NoAccessPermission.NoAccess
        ].title()}
      </MenuButton>
      <MenuList textAlign="left" zIndex="overlay">
        <MenuOptionGroup
          defaultValue={selected}
          value={selected}
          title={t`Permissions`}
          type="radio"
          onChange={(value) => {
            const permission = value as Permission | NoAccessPermission
            onClick(permission)
          }}
        >
          {options.map((option) => {
            const disabledOption = disabledOptions.find(
              (o) => o.permission === option
            )
            const label = disabledOption && disabledOption.reason
            return (
              <MenuItemOption
                key={option}
                isDisabled={Boolean(disabledOption)}
                value={option}
                alignItems="start"
                pt={3}
              >
                <GammaTooltip
                  label={label}
                  aria-label={label}
                  isDisabled={!disabledOption}
                  placement="top"
                  key={option}
                >
                  <Box>
                    <Text mt={-1}>
                      {PermissionMapToHumanReadable[option].title()}
                    </Text>
                    <Text fontSize="sm" color="gray.500" mt={1}>
                      {PermissionMapToHumanReadable[option].description()}
                    </Text>
                  </Box>
                </GammaTooltip>
              </MenuItemOption>
            )
          })}
          {onRemove && (
            <>
              <MenuDivider />
              <MenuItem
                color="red.500"
                onClick={onRemove}
                icon={<FontAwesomeIcon icon={regular('trash')} fixedWidth />}
              >
                <Trans>Remove</Trans>
              </MenuItem>
            </>
          )}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
