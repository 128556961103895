import { Link, Text, useToast } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Editor, JSONContent } from '@tiptap/core'
import { uniq } from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'

import { config } from 'config'
import { Doc } from 'modules/api'
import { useAppSelector } from 'modules/redux'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { selectTheme } from 'modules/tiptap_editor/reducer'
import { ExportFormat } from 'utils/export'

const PDF_EXPORT_MAX_DELAY = 30000

const getExportEndpoint = (docId: string, format: ExportFormat) =>
  `${config.API_HOST || ''}/export/docs/${docId}/${format}`

const fetchExportEndpoint = async (
  docId: string,
  docContent: JSONContent | undefined,
  format: ExportFormat
) => {
  const url = getExportEndpoint(docId, format)

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'share-token': config.SHARE_TOKEN || '',
    },
    body: JSON.stringify({
      docContent,
    }),
  })
}

type ExportSource = 'doc_menu' | 'share_panel'

export const useHandleExport = (
  doc: Doc,
  source: ExportSource,
  editor?: Editor,
  format: ExportFormat = 'pdf'
) => {
  const toast = useToast()
  const toastId = useRef<string | null>(null)
  const analytics = useAnalytics()
  const [exportInProgress, setExportInProgress] = useState(false)

  const showGeneratingToast = useCallback(() => {
    toastId.current = toast({
      id: `export-toast-${doc.id}-${format}`,
      isClosable: false,
      position: 'top',
      duration: null,
      status: 'loading',
      variant: 'light',
      title: <Trans>Exporting...</Trans>,
    }) as string
  }, [doc?.id, toast, format])

  const showLoadingToast = useCallback(() => {
    if (toastId.current) {
      toast.update(toastId.current, {
        status: 'loading',
        variant: 'light',
        title: <Trans>Exporting...</Trans>,
        description: (
          <Trans>
            This is taking a while. You can keep waiting, or close this and
            we'll email it to you.
          </Trans>
        ),
        isClosable: true,
      })
    }
  }, [toast, format])

  const showErrorToast = useCallback(() => {
    if (toastId.current) {
      toast.update(toastId.current, {
        title: <Trans>Error exporting file.</Trans>,
        status: 'error',
        duration: null,
        isClosable: true,
      })
    }
  }, [toast])

  const showSuccessToast = useCallback(
    (downloadLink: string | undefined) => {
      if (toastId.current) {
        toast.update(toastId.current, {
          status: 'success',
          title: <Trans>Your export is ready</Trans>,
          description: (
            <Text>
              <Trans>
                A download should start automatically. If not,{' '}
                <Link
                  textDecoration="underline"
                  target="_blank"
                  href={downloadLink ? downloadLink : undefined}
                  onClick={() =>
                    toastId.current && toast.close(toastId.current)
                  }
                >
                  click here to download it.
                </Link>
              </Trans>
            </Text>
          ),
          isClosable: true,
        })
      }
    },
    [toast, format]
  )

  const handleExport = useCallback(() => {
    // If editor is undefined (on the dashboard), we'll send an empty docContent and backend will use the latest snapshot
    const docContent = editor?.getJSON()

    setExportInProgress(true)
    showGeneratingToast()

    const exportWaitTimeout = setTimeout(showLoadingToast, PDF_EXPORT_MAX_DELAY)

    fetchExportEndpoint(doc.id, docContent, format)
      .then(async (response) => {
        const blob = await response.blob()
        if (!response.ok) {
          throw new Error('Export failed.')
        }
        const eventName =
          format === 'pdf'
            ? SegmentEvents.PDF_EXPORTED
            : SegmentEvents.PPTX_EXPORTED
        analytics?.track(eventName, {
          doc_id: doc.id,
          source,
        })
        showSuccessToast(
          decodeURIComponent(
            response.headers.get('x-gamma-download-url') || ''
          ) || undefined
        )

        // auto download
        // This feels a bit hacky but is apparently the best way to achieve auto download 🤷
        const blobUrl = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        const fileName = decodeURIComponent(
          (response.headers.get('Content-Disposition') || '')
            .split(';')
            .find((n) => n.includes('filename='))
            ?.replace('filename=', '')
            .trim() || `${doc.id}.${format}`
        )
        a.download = fileName
        a.href = blobUrl
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
      .catch(() => {
        showErrorToast()
      })
      .finally(() => {
        setExportInProgress(false)
        clearTimeout(exportWaitTimeout)
      })
  }, [
    analytics,
    doc?.id,
    editor,
    source,
    format,
    showErrorToast,
    showGeneratingToast,
    showLoadingToast,
    showSuccessToast,
  ])

  return { handleExport, exportInProgress }
}

export const useDownloadFontLinks = (doc: Doc) => {
  const editorTheme = useAppSelector(selectTheme)
  const theme = doc.theme || editorTheme
  const fontDownloadLinks = useMemo(() => {
    const fonts = theme.fonts ? [...theme.fonts] : []
    // If there's a missing heading/body font, then it will load Inter as the fallback
    if (fonts.length < 2) {
      fonts.push({
        id: 'Inter',
        name: 'Inter',
        url: 'https://fonts.googleapis.com/css2?family=Inter',
      })
    }

    return uniq(fonts)
      .map((font) => {
        if (font.url?.includes('fonts.googleapis.com')) {
          return {
            name: font.name,
            url: `https://fonts.google.com/specimen/${font.name}`,
          }
        } else {
          // todo: add custom fonts, Typekit fonts
          return {
            name: font.name,
          }
        }
      })
      .filter((f): f is { name: string; url?: string } => Boolean(f))
  }, [theme])
  return fontDownloadLinks
}
