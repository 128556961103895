import { Box, Button, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'

import { Doc } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { GAMMA_ARTIFACT_PROPER_NOUN } from 'modules/i18n/properNouns'
import { SegmentEvents } from 'modules/segment/segmentEvents'
import {
  useDownloadFontLinks,
  useHandleExport,
} from 'modules/sharing/exportHooks'
import {
  EventBusEvent,
  TiptapEventBus as tiptapEventEmitter,
} from 'modules/tiptap_editor/eventBus'
import { ExportFormat } from 'utils/export'

import { ExportButton } from '../ExportButton'
import { RemoveWatermarkUpsell } from './RemoveWatermark'

export const Export = ({
  doc,
  editor,
  onSharePanelOpen,
  onSharePanelClose,
}: {
  doc: Doc
  editor?: Editor
  onSharePanelOpen?: () => void
  onSharePanelClose: () => void
}) => {
  const enablePptExport = useFeatureFlag('pptExport')

  return (
    <VStack spacing={4} align="start" pt={2}>
      <Text fontSize="sm" color="gray.600">
        <Trans>
          Download a static copy of your {GAMMA_ARTIFACT_PROPER_NOUN} to share
          with others.
        </Trans>
      </Text>
      <Stack spacing={2} width="100%">
        <ExportDocButton
          format="pdf"
          label="PDF"
          icon={regular('file-pdf')}
          accentColor="red"
          doc={doc}
          editor={editor}
        />
        {enablePptExport && (
          <ExportDocButton
            format="pptx"
            label="PowerPoint"
            icon={regular('file-powerpoint')}
            accentColor="orange"
            doc={doc}
            editor={editor}
          />
        )}
      </Stack>
      {enablePptExport && <DownloadFontsButtons doc={doc} />}
      {editor && (
        <Text fontSize="sm" color="gray.600">
          <Trans>
            Tip: you can control card sizing and backdrops in{' '}
            <Button
              variant="link"
              as="span"
              size="sm"
              cursor="pointer"
              onClick={() => {
                onSharePanelClose()
                tiptapEventEmitter.emit(EventBusEvent.OPEN_PAGE_SETUP, 'cards')
              }}
            >
              page setup
            </Button>
            .
          </Trans>
        </Text>
      )}
      <RemoveWatermarkUpsell
        onClose={onSharePanelClose}
        onOpen={onSharePanelOpen}
        segmentEvent={
          SegmentEvents.GAMMA_PRO_UPSELL_EXPORT_SETTINGS_REMOVE_WATERMARK
        }
        doc={doc}
      />
    </VStack>
  )
}

const DownloadFontsButtons = ({ doc }: { doc: Doc }) => {
  const fontDownloadLinks = useDownloadFontLinks(doc)
  if (!fontDownloadLinks.length) return null

  return (
    <>
      <Text fontSize="sm" color="gray.600">
        <Trans>
          For fonts to display correctly in PowerPoint, you may need the
          following fonts:
        </Trans>
      </Text>
      <HStack>
        {fontDownloadLinks.map(({ name, url }) => {
          return (
            <Box
              border="1px"
              borderColor="gray.200"
              borderRadius="md"
              h="auto"
              key="name"
              _hover={
                url
                  ? {
                      boxShadow: 'var(--chakra-shadows-md)',
                      borderColor: 'var(--chakra-colors-trueblue-300)',
                      color: 'var(--chakra-colors-trueblue-400)',
                    }
                  : {}
              }
              onClick={url ? () => window.open(url, '_blank') : undefined}
              cursor={url ? 'pointer' : undefined}
            >
              <HStack p={3} paddingRight={4} spacing={4}>
                <Text fontWeight={'medium'}>{name}</Text>
                {url && (
                  <FontAwesomeIcon
                    icon={regular('arrow-up-right-from-square')}
                    size="1x"
                  />
                )}
              </HStack>
            </Box>
          )
        })}
      </HStack>
    </>
  )
}

type ExportDocButtonProps = {
  format: ExportFormat
  label: string
  icon: FontAwesomeIconProps['icon']
  accentColor: string
  doc: Doc
  editor?: Editor
  badge?: React.ReactNode
}

const ExportDocButton = ({
  format,
  doc,
  editor,
  icon,
  accentColor,
  label,
  badge,
}: ExportDocButtonProps) => {
  const { handleExport, exportInProgress } = useHandleExport(
    doc,
    'share_panel',
    editor,
    format
  )
  return (
    <ExportButton
      onClick={handleExport}
      icon={icon}
      formatLabel={label}
      accentColor={accentColor}
      exportInProgress={exportInProgress}
      exportInProgressNote={<Trans>It's safe to close the share panel.</Trans>}
      badge={badge}
    />
  )
}
