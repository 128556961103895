import { useToast } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { JSONContent } from '@tiptap/core'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { DocSource, useCreateDocMutation } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { DocCreatedSource } from 'modules/segment'
import { trackDocCreatedEvent } from 'modules/segment/helper'
import { emptyDoc, emptyDocWithQuickstart } from 'modules/tiptap_editor'
import { SCHEMA_VERSION } from 'modules/tiptap_editor/schema'
import { jsonToYDocSnapshot } from 'modules/tiptap_editor/utils/transform'
import { useUserContext } from 'modules/user'

type CreateDocAndNavigateParams = {
  source: DocCreatedSource
  document?: JSONContent
  channelId?: string | null
  title?: string
  historyFn?: 'replace' | 'push'
}

export const docCreatedSourceToSource: Record<DocCreatedSource, DocSource> = {
  example_deck_clone_toast: DocSource.DuplicateInspiration,
  import: DocSource.ImportGoogleDoc,
  blank: DocSource.Blank,
  template_browser: DocSource.DuplicateTemplate,
  get_started_modal: DocSource.Blank,
  template_modal_griditem: DocSource.DuplicateTemplate,
  template_dashboard_griditem: DocSource.DuplicateTemplate,
  template_modal_preview: DocSource.DuplicateTemplate,
  template_modal_preview_create_theme: DocSource.DuplicateTemplate,
  'slash-new': DocSource.SlashNew,
  editor_toolbar_doc_menu_duplicate: DocSource.DuplicateDoc,
  dashboard_docs_view_duplicate: DocSource.DuplicateDoc,
  ai_generated: DocSource.AiGenerated,
  ai_generated_advanced: DocSource.AiGenerated,
  sites_add_page: DocSource.SitesAddPage,
  sites_copy_to_site: DocSource.SitesCopyToSite,
}

export const useCreateDocAndNavigate = () => {
  const { user, currentWorkspace } = useUserContext()
  const cardLayoutsEnabled = useFeatureFlag('cardLayoutsEnabled')

  const [createDoc, { data, loading }] = useCreateDocMutation()

  const { push, replace } = useRouter()

  const toast = useToast()

  const createDocAndNavigate = useCallback(
    (params: CreateDocAndNavigateParams) => {
      const needsEditorOnboarding = user?.settings?.needsEditorOnboarding
      const isFirstDeck = needsEditorOnboarding
      const source = params.source
      const channelId = params?.channelId
      const historyFn = params?.historyFn === 'replace' ? replace : push
      const emptyDocType = isFirstDeck ? 'quickstart' : 'empty'
      if (!currentWorkspace?.id) {
        console.error('[createDocAndNavigate] No currentWorkspace found')
        return
      }
      const docFlags = {
        cardLayoutsEnabled,
      }
      const document = params?.document
        ? params.document
        : emptyDocType === 'empty'
        ? emptyDoc(user, { docFlags })
        : emptyDocWithQuickstart(user, { docFlags })
      const encodedDocument = jsonToYDocSnapshot(document)

      console.debug('[createDocAndNavigate] Creating doc', {
        document,
        encodedDocument,
      })

      return createDoc({
        variables: {
          workspaceId: currentWorkspace?.id,
          title: params?.title || '',
          source: docCreatedSourceToSource[params.source],
          initialContent: {
            ydoc: encodedDocument,
            schemaVersion: SCHEMA_VERSION,
            editors: [user?.id!],
          },
          ...(channelId ? { channels: [{ channelId }] } : {}),
        },
      })
        .then(async ({ data: createData }) => {
          trackDocCreatedEvent({
            doc_id: createData?.createDoc.id,
            source,
            channel: channelId ? channelId : null,
          })

          console.debug(
            '[createDocAndNavigate] Doc created. Navigating to Editor',
            createData?.createDoc.id
          )
          historyFn(
            `/docs/${createData?.createDoc.id}${
              isFirstDeck ? '?tour=true' : ''
            }`
          )
        })
        .catch((error) => {
          console.error('[createDocAndNavigate]', error)
          toast({
            title: <Trans>Error creating document</Trans>,
            description: error.message,
            position: 'top',
            status: 'error',
            isClosable: true,
            duration: null,
          })
        })
    },
    [
      cardLayoutsEnabled,
      createDoc,
      currentWorkspace?.id,
      push,
      replace,
      toast,
      user,
    ]
  )

  return [createDocAndNavigate, { data, loading }] as const
}
