import { Flex } from '@chakra-ui/react'
import { Global } from '@emotion/react'
import { NextPage } from 'next'

import { ButterbarManager } from 'gamma_components/Butterbar/Butterbar'

type HomeLayoutProps = {
  children: React.ReactNode
}

export const HomeLayout = ({ children }: HomeLayoutProps): JSX.Element => {
  return (
    <Flex
      width="100%"
      height="100%"
      direction="column"
      minHeight="var(--100vh)"
      bgColor="gray.100"
      color="gray.800"
      p={0}
    >
      <Global
        styles={{
          body: {
            overflowY: 'scroll',
          },
        }}
      />
      <ButterbarManager />
      {children}
    </Flex>
  )
}

export function withHomeLayout<T extends Record<string, any>>(
  Component: NextPage<T>
) {
  const WithLayoutComponent = (props: T) => (
    <HomeLayout>
      <Component {...props} />
    </HomeLayout>
  )

  if ('getInitialProps' in Component) {
    WithLayoutComponent.getInitialProps = Component.getInitialProps
  }

  return WithLayoutComponent
}
