import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Card,
  CardBody,
  Divider,
  Flex,
  HStack,
  Image,
  Link,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { slugifyLowercase } from '@gammatech/lib/dist/slugify'
import { Trans } from '@lingui/macro'
import { nanoid } from 'nanoid'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'

import { FullPageSpinner } from 'gamma_components'
import {
  Doc,
  DocSource,
  Permission,
  SiteFragmentFragment,
  useDuplicateDocMutation,
  useGetSitesQuery,
  useSetRouteMutation,
} from 'modules/api'
import { GAMMA_ARTIFACT_PROPER_NOUN } from 'modules/i18n/properNouns'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { forceSaveDoc, getSiteManagerUrlSingleRoute } from 'modules/sites/utils'
import { useUserContext } from 'modules/user'

const getTemporaryPath = ({
  title,
  site,
}: {
  title?: string
  site?: Pick<SiteFragmentFragment, 'routes'>
}) => {
  // Always prefix path with slash
  let path = `/${slugifyLowercase(title || 'untitled')}`
  if (site?.routes?.find((r) => r.path === path)) {
    // This seems less willy-nilly than using a random number, but it's still not ideal.
    // TODO: Figure out if we want to let people set a route at the outset
    path = `${path}-${nanoid(6)}`
  }
  return path
}

const SITES_HELP_ARTICLE_LINK =
  'https://help.gamma.app/en/articles/8429268-how-to-publish-your-page-to-a-custom-domain'

const cardStylingProps = {
  variant: 'outline',
  transitionProperty: 'all',
  transitionDuration: 'normal',
  cursor: 'pointer',
  boxShadow: 'none',
  tabIndex: 0,
  _hover: {
    shadow: 'lg',
    borderColor: 'trueblue.300',
    bg: 'trueblue.50',
  },
  _focusVisible: { boxShadow: 'none', outline: 'none' },
  _focus: {
    boxShadow: 'outline',
  },
}
type CopyToSiteInnerProps = {
  doc: Pick<Doc, 'id' | 'title' | 'orgAccess'>
  onCreateSiteClick: () => void
}
export const CopyToSiteInner = ({
  doc,
  onCreateSiteClick,
}: CopyToSiteInnerProps) => {
  const analytics = useAnalytics()
  const [duplicateDoc] = useDuplicateDocMutation()
  const { currentWorkspace } = useUserContext()
  const [setRoute] = useSetRouteMutation()
  const [isSettingRoute, setIsSettingRoute] = useState(false)

  const { data, loading } = useGetSitesQuery({
    variables: {
      workspaceId: currentWorkspace?.id as string,
    },
    skip: !currentWorkspace?.id,
  })
  const toast = useToast()
  const { push } = useRouter()
  const sites = data?.sites

  const onCopyDocToSite = useCallback(
    async (siteId: string) => {
      setIsSettingRoute(true)
      const site = sites?.find((s) => s.id === siteId)
      const docId = doc.id

      if (!currentWorkspace?.id) {
        console.error('[CopyToSiteModal] No currentWorkspace found')
        return
      }

      // Route names need to be unique
      const path = getTemporaryPath({
        title: doc.title,
        site,
      })

      await forceSaveDoc(docId)
      // Update doc orgOccess to manage
      duplicateDoc({
        variables: {
          duplicateDocInput: {
            sourceDocId: docId as string,
            source: DocSource.SitesCopyToSite,
            title: doc.title,
            orgId: currentWorkspace?.id as string,
            orgAccess: Permission.Manage,
            // @ts-ignore
            themeId: null,
          },
        },
      })
        .then((docData) => {
          const newDocId = docData?.data?.duplicateDoc.id
          if (!newDocId) {
            console.error('[CopyToSiteModal] No docId found')
            return
          }
          analytics?.track(SegmentEvents.DOC_COPIED_TO_SITE, {
            doc_id: docId,
            site_id: siteId,
            source: DocSource.SitesCopyToSite,
          })
          setRoute({
            variables: {
              input: {
                siteId: siteId as string,
                docId: newDocId,
                path,
              },
            },
            refetchQueries: ['GetSite'],
          })
            .then(() => {
              const url = getSiteManagerUrlSingleRoute(siteId, newDocId)
              console.debug(
                '[CopyToSiteModal] Doc added to site. Adding route then navigating to editor',
                docId
              )
              toast({
                position: 'top',
                title: <Trans>Success!</Trans>,
                description: (
                  <Trans>Redirecting you to the website editor...</Trans>
                ),
                status: 'success',
                duration: 5000,
              })
              push(url)
            })
            .catch((e) => {
              toast({
                position: 'top',
                title: 'Error',
                description: e.message,
                status: 'error',
                duration: 5000,
              })
              setIsSettingRoute(false)
              return
            })
            .finally(() => {
              setIsSettingRoute(false)
            })
        })
        .catch((e) => {
          toast({
            position: 'top',
            title: 'Error',
            description: e.message,
            status: 'error',
            duration: 5000,
          })
          setIsSettingRoute(false)
          return
        })
    },
    [
      analytics,
      currentWorkspace?.id,
      doc.id,
      doc.title,
      duplicateDoc,
      push,
      setRoute,
      sites,
      toast,
    ]
  )

  const showLoading = loading || isSettingRoute

  return (
    <>
      {showLoading && (
        <Flex w="100" h="400px" maxH="30vh">
          <FullPageSpinner />
        </Flex>
      )}
      {!showLoading && (
        <Stack spacing={4}>
          <Stack pt={2} pb={2}>
            <Text
              fontSize="sm"
              letterSpacing="normal"
              color="gray.600"
              fontWeight="500"
            >
              <Trans>
                Publish this {GAMMA_ARTIFACT_PROPER_NOUN} to the web.
              </Trans>
            </Text>{' '}
            <Text
              fontSize="xs"
              letterSpacing="normal"
              color="gray.600"
              fontWeight="500"
            >
              <Trans>
                When you copy a {GAMMA_ARTIFACT_PROPER_NOUN} into a site, keep
                in mind that everyone in your workspace will have{' '}
                <strong>full access</strong> to it.
              </Trans>
              <Link
                href={SITES_HELP_ARTICLE_LINK}
                textDecoration="underline"
                isExternal
                color="trueblue.500"
              >
                <Trans>Learn more about sites</Trans>
                <ExternalLinkIcon mx="2px" />
              </Link>{' '}
            </Text>
          </Stack>
          {sites && sites.length > 0 && (
            <>
              <Text
                fontSize="sm"
                letterSpacing="normal"
                color="gray.600"
                fontWeight="500"
              >
                <Trans>
                  Which website do you want to copy this{' '}
                  {GAMMA_ARTIFACT_PROPER_NOUN} to?
                </Trans>
              </Text>
              {sites?.map((site) => (
                <Card
                  key={site.id}
                  onClick={() => onCopyDocToSite(site.id)}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      onCopyDocToSite(site.id)
                    }
                  }}
                  {...cardStylingProps}
                >
                  <CardBody>
                    <HStack>
                      <Stack spacing={0}>
                        <HStack>
                          {site.faviconUrl && (
                            <Image w="4" src={site.faviconUrl} />
                          )}
                          <Text fontWeight="600">{site.name}</Text>
                        </HStack>
                      </Stack>
                      <Spacer />
                      <FontAwesomeIcon icon={regular('arrow-right')} />
                    </HStack>
                  </CardBody>
                </Card>
              ))}
              <HStack>
                <Divider />
                <Text fontSize="sm" color="gray.500">
                  <Trans>Or</Trans>
                </Text>
                <Divider />
              </HStack>
            </>
          )}

          <Card
            onClick={() => {
              onCreateSiteClick()
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                onCreateSiteClick()
              }
            }}
            {...cardStylingProps}
          >
            <CardBody>
              <HStack>
                <Stack spacing={0}>
                  <Text fontWeight="600">
                    <Trans>Copy to a new site...</Trans>
                  </Text>
                  <HStack>
                    <Text fontSize="sm" color="gray.500">
                      <Trans>
                        A copy of this {GAMMA_ARTIFACT_PROPER_NOUN} will become
                        the homepage for your new site.
                      </Trans>
                    </Text>
                  </HStack>
                </Stack>
                <Spacer />
                <FontAwesomeIcon icon={regular('plus')} />
              </HStack>
            </CardBody>
          </Card>
        </Stack>
      )}
    </>
  )
}
