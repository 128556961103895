import {
  Avatar,
  Circle,
  Flex,
  HStack,
  Tag,
  TagCloseButton,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'
import { validate as validateEmail } from 'email-validator'

import { Channel, User } from 'modules/api'
import { CHANNELS_ICON } from 'modules/sharing/constants'

const TAG_WIDTH_INITIAL = 170
const TAG_WIDTH_STANDARD = 300

interface InvitedUser {
  email: string
}

type UserOrInvitedUser = User | InvitedUser

type SearchBarCollaboratorTagProps = {
  selectedCollaborator: UserOrInvitedUser
  idx: number
  handleRemoveClick: () => void
}
export const SearchBarCollaboratorTag = ({
  selectedCollaborator,
  idx,
  handleRemoveClick,
}: SearchBarCollaboratorTagProps) => {
  const isValidEmail =
    selectedCollaborator && validateEmail(selectedCollaborator?.email || '')
  const tagStyles = !isValidEmail
    ? {
        colorScheme: 'red',
        bg: 'red',
        color: 'white',
      }
    : {}
  // Overflow for the first token needs to be smaller'
  // to account for the permission chooser.
  const textMaxW = idx === 0 ? TAG_WIDTH_INITIAL : TAG_WIDTH_STANDARD

  return (
    // Force 2nd tag onto the next line to make room
    // for the permission chooser. 112px accounts
    // for the widest permission text (Comment)
    <Flex
      key={
        'id' in selectedCollaborator
          ? selectedCollaborator.id
          : selectedCollaborator.email
      }
      width={idx === 0 ? 'calc(100% - 100px)' : ''}
      className="searchbartag"
      data-testid={`searchbartag-collaborator-${selectedCollaborator.email}`}
    >
      <Tag size="md" minW={110} {...tagStyles}>
        {'id' in selectedCollaborator ? (
          <GammaTooltip
            label={selectedCollaborator.email}
            aria-label={selectedCollaborator.email}
          >
            <HStack>
              <Avatar
                size="2xs"
                m={0}
                name={selectedCollaborator.displayName}
                src={selectedCollaborator.profileImageUrl}
              />
              <Text
                textTransform="capitalize"
                wordBreak="break-word"
                noOfLines={1}
                maxW={textMaxW}
                lineHeight="1.5"
              >
                {selectedCollaborator.displayName}
              </Text>
            </HStack>
          </GammaTooltip>
        ) : (
          <GammaTooltip
            label={
              isValidEmail ? (
                selectedCollaborator.email
              ) : (
                <Trans>Invalid email address</Trans>
              )
            }
            aria-label={selectedCollaborator.email}
          >
            <HStack flex={1}>
              <Circle bg={isValidEmail ? 'trueblue.100' : 'none'} size={6}>
                <FontAwesomeIcon
                  icon={
                    isValidEmail ? regular('envelope') : regular('exclamation')
                  }
                />
              </Circle>
              <Text
                wordBreak="break-all"
                display="block"
                height="100%"
                width="100%"
                noOfLines={1}
                maxW={textMaxW}
                lineHeight="1.5"
              >
                {selectedCollaborator.email}
              </Text>
            </HStack>
          </GammaTooltip>
        )}
        <TagCloseButton onClick={handleRemoveClick} />
      </Tag>
    </Flex>
  )
}

type SearchBarChannelTagProps = {
  selectedChannel: Channel
  idx: number
  handleRemoveClick: () => void
}
export const SearchBarChannelTag = ({
  selectedChannel,
  idx,
  handleRemoveClick,
}: SearchBarChannelTagProps) => {
  // Overflow for the first token needs to be smaller'
  // to account for the permission chooser.
  const textMaxW = idx === 0 ? TAG_WIDTH_INITIAL : TAG_WIDTH_STANDARD

  return (
    // Force 2nd tag onto the next line to make room
    // for the permission chooser. 105px accounts
    // for the widest permission text (Full Access)
    <Flex
      key={selectedChannel.id}
      width={idx === 0 ? 'calc(100% - 100px)' : ''}
      className="searchbartag"
      data-testid={`searchbartag-channel-${selectedChannel.id}`}
    >
      <Tag size="md" minW={105}>
        <GammaTooltip
          label={selectedChannel.name}
          aria-label={selectedChannel.name}
        >
          <HStack flex={1}>
            <Circle bg={'trueblue.100'} size={6}>
              <FontAwesomeIcon icon={CHANNELS_ICON} />
            </Circle>
            <Text
              wordBreak="break-all"
              display="block"
              height="100%"
              width="100%"
              noOfLines={1}
              maxW={textMaxW}
              lineHeight="1.5"
            >
              {selectedChannel.name}
            </Text>
          </HStack>
        </GammaTooltip>

        <TagCloseButton onClick={handleRemoveClick} />
      </Tag>
    </Flex>
  )
}
