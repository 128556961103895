import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { DarkModeProvider } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import { useCallback, useRef, useState } from 'react'

import {
  Doc,
  DocSource,
  Permission,
  useCreateSiteMutation,
  useDuplicateDocMutation,
} from 'modules/api'
import { useAddNewPageToSite } from 'modules/sites/hooks/useAddNewPageToSite'
import { forceSaveDoc, getSiteManagerUrl } from 'modules/sites/utils'
import { useUserContext } from 'modules/user'

type CreateSiteModalProps = {
  isOpen: boolean
  onClose: () => void
  existingDoc?: Pick<Doc, 'id' | 'title' | 'theme'>
}

export const CreateSiteModal = ({
  isOpen,
  onClose,
  existingDoc,
}: CreateSiteModalProps) => {
  const toast = useToast()
  const inputRef = useRef<HTMLInputElement>(null)
  const [isCreating, setIsCreating] = useState(false)
  const { currentWorkspace } = useUserContext()
  const [name, setName] = useState('')
  const [createSite] = useCreateSiteMutation()
  const [duplicateDoc] = useDuplicateDocMutation()

  const addNewPageToSite = useAddNewPageToSite()
  const { push } = useRouter()

  const onCreateSiteFromExistingPage = useCallback(async () => {
    if (!existingDoc?.id) {
      return
    }
    await forceSaveDoc(existingDoc.id)
    setIsCreating(true)

    try {
      const { data: duplicateDocData } = await duplicateDoc({
        variables: {
          duplicateDocInput: {
            sourceDocId: existingDoc.id,
            source: DocSource.SitesCopyToSite,
            title: existingDoc?.title || t`Untitled`,
            orgId: currentWorkspace?.id as string,
            orgAccess: Permission.Manage,
            // @ts-ignore
            themeId: null,
          },
        },
      })

      const newDocId = duplicateDocData?.duplicateDoc?.id

      if (!newDocId) {
        console.error(
          '[CreateSiteModal] Failed to duplicate doc with id',
          existingDoc.id
        )
        return
      }
      const { data } = await createSite({
        variables: {
          input: {
            workspaceId: currentWorkspace?.id as string,
            name,
            routes: [{ docId: newDocId, path: '/' }],
            themeId: existingDoc?.theme?.id,
          },
        },
      })
      const site = data?.createSite

      if (!site) {
        console.error(
          '[CreateSiteModal] Failed to create site, no site returned'
        )
        return
      }

      push(getSiteManagerUrl(site.id, site.name))
    } catch (e) {
      toast({
        status: 'error',
        title: <Trans>Failed to create site</Trans>,
        description: <Trans>Something went wrong. Please try again</Trans>,
      })
      console.error(e)
    }
    setIsCreating(false)
  }, [
    createSite,
    currentWorkspace?.id,
    duplicateDoc,
    existingDoc,
    name,
    push,
    toast,
  ])

  const onCreateSiteWithNewEmptyPage = useCallback(async () => {
    setIsCreating(true)
    const { data } = await createSite({
      variables: {
        input: {
          workspaceId: currentWorkspace?.id as string,
          name,
          routes: [],
        },
      },
    })
    const site = data?.createSite

    if (!site) {
      console.error('[CreateSiteModal] Failed to create site, no site returned')
      return
    }

    addNewPageToSite({
      siteId: site?.id,
      routes: site?.routes,
      title: 'Home',
      path: '/',
    }).then(() => {
      push(getSiteManagerUrl(site.id, site.name))
      setIsCreating(false)
    })
  }, [addNewPageToSite, createSite, currentWorkspace?.id, name, push])

  const onCreateSite = useCallback(async () => {
    return existingDoc?.id
      ? onCreateSiteFromExistingPage()
      : onCreateSiteWithNewEmptyPage()
  }, [
    existingDoc?.id,
    onCreateSiteFromExistingPage,
    onCreateSiteWithNewEmptyPage,
  ])

  const isValid = name.length > 0
  return (
    <DarkModeProvider isDark={false}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={inputRef}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Trans>Create site</Trans>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>
                  <Trans>Site name</Trans>
                </FormLabel>
                <Input
                  ref={inputRef}
                  placeholder={t`My site`}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (!isValid) {
                      return
                    }
                    if (e.key === 'Enter') {
                      onCreateSite()
                    }
                  }}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button variant="ghost" onClick={onClose}>
                <Trans>Cancel</Trans>
              </Button>
              <Button
                variant="solid"
                onClick={onCreateSite}
                isDisabled={!isValid}
                isLoading={isCreating}
              >
                <Trans>Create site</Trans>
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DarkModeProvider>
  )
}
