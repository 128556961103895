import { Flex, ThemeProvider } from '@chakra-ui/react'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'

import { config } from 'config'
import { MarketingFooter } from 'modules/marketing_pages/components/MarketingUI/components/MarketingFooter'
import {
  HeaderMode,
  MarketingHeader,
} from 'modules/marketing_pages/components/MarketingUI/components/MarketingHeader'
import { marketingTheme } from 'modules/marketing_pages/components/MarketingUI/marketingTheme'
import {
  GA_TRACKING_ID,
  gtagPageview,
} from 'modules/marketing_pages/components/MarketingUI/utils/gtag'
import EudoxusFontLinks from 'scss/fonts/Eudoxus-Sans'
import { isRobot } from 'utils/deviceDetection'

const hasDebugCookie =
  config.IS_CLIENT_SIDE && document.cookie.includes('gammaGADebug=true')

const isGAEnabled = () =>
  (config.APPLICATION_ENVIRONMENT === 'production' && !isRobot()) ||
  hasDebugCookie

type MarketingLayoutProps = {
  children: React.ReactNode
  headerMode?: HeaderMode
}

export const MarketingLayout = ({
  children,
  headerMode,
}: MarketingLayoutProps): JSX.Element => {
  const router = useRouter()

  useEffect(() => {
    if (!isGAEnabled()) {
      return
    }
    // This follows the guidance from
    // https://github.com/vercel/next.js/blob/5e50fce03b893dad4e36b6ce8fcf53ab6ebe348f/examples/with-google-analytics/pages/_app.js#L8-L18
    const handleRouteChange = (url) => {
      gtagPageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <Flex
      direction="column"
      width="100%"
      height="100%"
      minHeight="var(--100vh)"
      bg="#EDE9E8"
      color="gray.800"
      overflowX="hidden"
    >
      {isGAEnabled() && (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          />
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
            `,
            }}
          />
        </>
      )}
      <MarketingHeader headerMode={headerMode} />
      <Flex
        zIndex="1"
        id="main"
        flex="1"
        justifyContent="flex-start"
        alignItems="center"
        direction="column"
        position="relative"
      >
        {children}
      </Flex>
      <MarketingFooter />
    </Flex>
  )
}

type MarketingLayoutOptions = {
  headerMode?: HeaderMode
}
export function withMarketingLayout<T extends Record<string, any>>(
  Component: NextPage<T>,
  marketingLayoutOptions?: MarketingLayoutOptions
) {
  const WithLayoutComponent = (props: T) => {
    const { headerMode } = marketingLayoutOptions || {}
    return (
      <ThemeProvider theme={marketingTheme}>
        <EudoxusFontLinks />
        <MarketingLayout headerMode={headerMode}>
          <Component {...props} />
        </MarketingLayout>
      </ThemeProvider>
    )
  }

  if ('getInitialProps' in Component) {
    WithLayoutComponent.getInitialProps = Component.getInitialProps
  }

  return WithLayoutComponent
}
