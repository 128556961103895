import { useRouter } from 'next/router'
import { useCallback } from 'react'

import {
  DocSource,
  Permission,
  Route,
  useCreateDocMutation,
  useSetRouteMutation,
} from 'modules/api'
import { trackDocCreatedEvent } from 'modules/segment/helper'
import { getSiteManagerUrlSingleRoute } from 'modules/sites/utils'
import { emptyDoc } from 'modules/tiptap_editor'
import { SCHEMA_VERSION } from 'modules/tiptap_editor/schema'
import { jsonToYDocSnapshot } from 'modules/tiptap_editor/utils/transform'
import { useUserContext } from 'modules/user/context'

export const useAddNewPageToSite = () => {
  const { user, currentWorkspace } = useUserContext()
  const [createDoc] = useCreateDocMutation()
  const [setRoute] = useSetRouteMutation()
  const { push } = useRouter()

  const addNewPageToSite = useCallback(
    async ({
      title,
      path,
      siteId,
      routes,
    }: {
      title: string
      path: string
      siteId?: string
      routes?: Pick<Route, 'docId' | 'path'>[]
    }) => {
      if (!path.startsWith('/')) {
        console.error(
          '[AddNewPageToSite] Path must start with /. Aborting.',
          path
        )
        return
      }
      if (!currentWorkspace?.id || !siteId || !routes) {
        return
      }
      const document = emptyDoc(user, {
        docFlags: {
          // We should probably remove this feature flag check from useCreateDocAndNavigate, huh?
          cardLayoutsEnabled: true,
        },
      })
      const encodedDocument = jsonToYDocSnapshot(document)
      const { data: createData } = await createDoc({
        variables: {
          workspaceId: currentWorkspace?.id as string,
          title,
          source: DocSource.SitesAddPage,
          initialContent: {
            ydoc: encodedDocument,
            schemaVersion: SCHEMA_VERSION,
            editors: [user?.id!],
          },
          orgAccess: Permission.Manage,
        },
      })

      const docId = createData?.createDoc.id
      trackDocCreatedEvent({
        doc_id: docId,
        source: DocSource.SitesAddPage,
        channel: null,
      })

      if (!siteId || !docId) {
        console.error(
          '[AddPageModal] Site or doc id is undefined. Cannot add route.'
        )
        return
      }

      await setRoute({
        variables: {
          input: {
            siteId: siteId,
            docId,
            path,
          },
        },
        refetchQueries: ['GetSite'],
      })

      const url = getSiteManagerUrlSingleRoute(siteId, docId)
      console.debug(
        '[AddPageModal] Doc created. Adding route then navigating to editor',
        docId
      )
      await push(url)
      return docId
    },
    [setRoute, createDoc, currentWorkspace?.id, push, user]
  )

  return addNewPageToSite
}
