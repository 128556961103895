import { useToast } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { JSONContent } from '@tiptap/core'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { useDuplicateDocMutation } from 'modules/api'
import { docCreatedSourceToSource } from 'modules/api/hooks'
import { DocCreatedSource, SegmentEvents, useAnalytics } from 'modules/segment'
import { trackDocCreatedEvent } from 'modules/segment/helper'
import { useUserContext } from 'modules/user'

import { SCHEMA_VERSION } from '../schema'
import { jsonToYDocSnapshot } from './transform'

/**
 * Provides a way to duplicate a doc.
 * 1) [REQUIRED] If we only supply `docId` to the API, the API will take care
 *    of ascertaining the latest snapshot in the database.
 * 2) [OPTIONAL] If we pass `initialContent`, as we might directly from the editor,
 *    then the API will use that to create the duplicate
 * 3) [OPTIONAL] If we pass `channelId`, then the API will duplicate the doc
 *    associated with the given `channelId`.
 * 4) [OPTIONAL] If we pass a `docTitle`, we can use it in success toast messages
 *    or analytics tracking.
 * 5) [REQUIRED] We must pass a `source` to track where the duplication is coming from.
 * 6) [OPTIONAL] We must pass an `onSuccess` callback to handle any post-duplication
 *    logic.
 */
export const useDuplicateDoc = () => {
  const [duplicateDoc] = useDuplicateDocMutation({
    refetchQueries: ['GetDocs'],
  })
  const { push } = useRouter()

  const toast = useToast()
  const { user, currentWorkspace } = useUserContext()
  const analytics = useAnalytics()
  const duplicate = useCallback(
    ({
      docId,
      docTitle,
      initialContent,
      channelId,
      source,
      openThemeEditor,
      onSuccess,
      newWindow,
    }: {
      docId?: string | null
      docTitle?: string
      initialContent?: JSONContent | null
      channelId?: string | null
      source: DocCreatedSource
      openThemeEditor?: boolean
      onSuccess?: (id: string) => void
      newWindow?: boolean
    }) => {
      const needsEditorOnboarding = user?.settings?.needsEditorOnboarding
      if (!docId) {
        return Promise.reject(new Error('Cannot duplicate without a docId'))
      }
      const duplicateToast = toast({
        id: `duplicate-toast-${docId}`,
        isClosable: false,
        position: 'top',
        status: 'loading',
        variant: 'light',
        duration: null,
        title: <Trans>Copying...</Trans>,
      })

      if (!user || !currentWorkspace?.id) {
        console.error(
          `[Duplicate.${source}] Cannot duplicate without a user or workspace`,
          { user }
        )
        return Promise.reject(
          new Error('Cannot duplicate without a user or workspace')
        )
      }

      return duplicateDoc({
        variables: {
          duplicateDocInput: {
            orgId: currentWorkspace?.id,
            sourceDocId: docId,
            source: docCreatedSourceToSource[source],
            initialContent: initialContent
              ? {
                  ydoc: jsonToYDocSnapshot(initialContent),
                  schemaVersion: SCHEMA_VERSION,
                  editors: [user?.id!],
                }
              : undefined,
            ...(channelId ? { channels: [{ channelId }] } : {}),
          },
        },
      })
        .then(
          (result) => {
            if (!result?.data?.duplicateDoc) {
              throw new Error(`No data received`)
            }
            analytics?.track(SegmentEvents.EXAMPLE_DUPLICATED, {
              doc_id: docId,
              title: docTitle,
              source: source,
            })
            const { id } = result?.data?.duplicateDoc
            trackDocCreatedEvent({
              doc_id: id,
              source: source,
              source_doc_id: docId,
            })
            console.debug(
              `[Duplicate.${source}] Doc duplicated New doc id (${id}). Navigating to Editor`
            )
            toast.update(duplicateToast, {
              status: 'success',
              description: <Trans>Success</Trans>,
              duration: 5000,
              isClosable: true,
            })
            const isFirstDeck = needsEditorOnboarding
            const url = openThemeEditor
              ? `/docs/${id}${openThemeEditor ? `?openThemeEditor=true` : ''}`
              : isFirstDeck
              ? `/docs/${id}?tour=true`
              : `/docs/${id}`
            if (newWindow) {
              window.open(url, '_blank')
            } else {
              push(url)
            }
            if (onSuccess && id) {
              onSuccess(id)
            }
          },
          (e) => {
            console.error(
              `[Duplicate.${source}] Error duplicating doc:`,
              e.message
            )
            toast.update(duplicateToast, {
              title: <Trans>Error duplicating doc</Trans>,
              description: e.message,
              status: 'error',
              duration: null,
              isClosable: true,
            })
          }
        )
        .catch((e) => {
          console.error(
            `[Duplicate.${source}] Error duplicating doc:`,
            e.message
          )
          toast.update(duplicateToast, {
            title: <Trans>Error duplicating doc</Trans>,
            description: e.message,
            status: 'error',
            duration: null,
            isClosable: true,
          })
        })
    },
    [currentWorkspace?.id, user, toast, duplicateDoc, analytics, push]
  )

  return duplicate
}
