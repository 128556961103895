import { analytics } from 'modules/segment'

import { DocCreatedSource, SegmentEvents } from './segmentEvents'

export const trackDocCreatedEvent = ({
  doc_id,
  source,
  source_doc_id,
  channel,
}: {
  source: DocCreatedSource
  doc_id?: string
  channel?: string | null
  source_doc_id?: string
}) => {
  analytics?.track(SegmentEvents.DOC_CREATED, {
    doc_id,
    source_doc_id,
    source,
    channel,
  })
}

export const trackDocShareLinkCopiedEvent = ({
  docId,
  type,
  source,
}: {
  docId?: string
  type: 'tokenUrl' | 'public' | 'embed'
  source: 'sharePanel' | 'docWizardFooter'
}) => {
  if (!docId) {
    console.debug('[trackDocShareLinkCopiedEvent]: doc_id is required')
    return
  }
  analytics?.track(SegmentEvents.DOC_SHARELINK_COPIED, {
    doc_id: docId,
    source,
    type,
  })
}
