import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'
import React from 'react'

interface PermissionsSettingsRowProps {
  title: React.ReactNode
  subtitle?: React.ReactNode
  img: JSX.Element
  isDisabled?: boolean
  disabledText: React.ReactNode
  tooltipText?: string
  testId?: string
  infoIcon?: JSX.Element
  permissionsControl: JSX.Element
  workspaceGuestInvite?: JSX.Element
}

export const PermissionsSettingsRow = ({
  title,
  subtitle,
  img,
  isDisabled = false,
  disabledText,
  tooltipText,
  testId,
  infoIcon,
  permissionsControl,
  workspaceGuestInvite,
}: PermissionsSettingsRowProps) => {
  const subtitleComponent =
    typeof subtitle == 'string' ? (
      <Text fontSize="xs" color="gray.500">
        {subtitle}
      </Text>
    ) : (
      subtitle
    )

  const permissionsComponent = (
    <GammaTooltip isDisabled={!tooltipText} label={tooltipText} placement="top">
      <Box>{permissionsControl}</Box>
    </GammaTooltip>
  )

  return (
    <HStack
      w="100%"
      justify="space-between"
      py={2}
      data-testid={`permission-settings-row${testId ? '-' + testId : ''}`}
    >
      <Box>
        <Flex alignItems="center">
          {img}
          <Box ml={3}>
            <Text>{title}</Text>
            <Flex align="center">
              {workspaceGuestInvite && workspaceGuestInvite}
              {subtitleComponent && subtitleComponent}
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box>
        <GammaTooltip
          isDisabled={!tooltipText}
          label={<Text>{tooltipText}</Text>}
          placement="top"
        >
          {isDisabled ? (
            <Text
              size="sm"
              color="gray.500"
              data-testid={`permission-controls-disabled-${testId}`}
            >
              {disabledText}
            </Text>
          ) : infoIcon ? (
            <HStack>
              {infoIcon}
              {permissionsComponent}
            </HStack>
          ) : (
            permissionsComponent
          )}
        </GammaTooltip>
      </Box>
    </HStack>
  )
}
