import {
  Button,
  Textarea,
  useClipboard,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useCallback, useEffect, useRef, useState } from 'react'

import { trackDocShareLinkCopiedEvent } from 'modules/segment/helper'

const EMBED_COPIED_TOAST_ID = 'embed-copied-toast'

export const EmbedCodeCopier = ({
  embedCode,
  isDisabled,
  docId,
}: {
  embedCode: string
  isDisabled: boolean
  docId: string
}) => {
  const toast = useToast()
  const [isSelected, setIsSelected] = useState(false)
  const { hasCopied, onCopy } = useClipboard(embedCode)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (!textareaRef.current) {
      return
    }
    if (isSelected) {
      textareaRef.current.focus()
      textareaRef.current.select()
    }
  }, [isSelected])

  const onTextareaClick = useCallback(() => setIsSelected(true), [])
  const onTextareaBlur = useCallback(() => setIsSelected(false), [])

  const onCopyClick = useCallback(() => {
    onCopy()
    if (!toast.isActive(EMBED_COPIED_TOAST_ID)) {
      toast({
        id: EMBED_COPIED_TOAST_ID,
        title: <Trans>Embed code copied to clipboard</Trans>,
        status: 'success',
        duration: 5000,
      })
    }
    trackDocShareLinkCopiedEvent({
      docId,
      source: 'sharePanel',
      type: 'embed',
    })
  }, [docId, onCopy, toast])

  return (
    <VStack spacing={4} align="stretch">
      <Textarea
        ref={textareaRef}
        rows={5}
        variant="filled"
        fontFamily="mono"
        colorScheme="gray"
        disabled={isDisabled}
        onClick={onTextareaClick}
        onBlur={onTextareaBlur}
        isReadOnly
        resize="none"
        pointerEvents={isDisabled ? 'none' : undefined}
      >
        {embedCode}
      </Textarea>
      <Button
        alignSelf="flex-end"
        onClick={onCopyClick}
        isDisabled={isDisabled}
        size="sm"
        // These values should be the same as the for the button in the LinkCopier component: packages/client/src/gamma_components/LinkCopier.tsx
        {...(isDisabled
          ? {
              opacity: '1 !important',
              color: 'gray.400',
              _hover: {
                opacity: '1',
              },
              _active: {
                boxShadow: '0 none',
              },
            }
          : {})}
      >
        {hasCopied ? <Trans>Copied</Trans> : <Trans>Copy embed code</Trans>}
      </Button>
    </VStack>
  )
}
