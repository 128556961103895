import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Plural, Trans } from '@lingui/macro'

type UnsentInvitationsModalProps = {
  isOpen: boolean
  onClose: () => void
  onSendInvitationsClick: () => void
  onDiscardClick: () => void
  invitationCount: number
}
export const UnsentInvitationsModal = ({
  invitationCount,
  isOpen,
  onClose,
  onSendInvitationsClick,
  onDiscardClick,
}: UnsentInvitationsModalProps) => {
  if (!isOpen) return null

  return (
    <Modal
      returnFocusOnClose={false}
      onClose={onClose}
      closeOnOverlayClick={true}
      isOpen={true}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Plural
            value={invitationCount}
            one="Unsent invitation"
            other="Unsent invitations"
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            <Plural
              value={invitationCount}
              one="You have # unsent invitation. Do you want to discard or send it?"
              other="You have # unsent invitations. Do you want to discard or send them?"
            />
          </Text>
        </ModalBody>
        <ModalFooter>
          <Stack spacing={2} direction="row" align="center">
            <Button onClick={onDiscardClick}>
              <Trans>Discard</Trans>
            </Button>
            <Button variant="solid" onClick={onSendInvitationsClick}>
              <Plural
                value={invitationCount}
                one="Send invitation"
                other="Send invitations"
              />
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
