import {
  Box,
  Button,
  FormControl,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'

import { LinkCopier } from 'gamma_components/LinkCopier'
import { Doc, Permission, useUpdateDocPublicAccessMutation } from 'modules/api'
import { GAMMA_ARTIFACT_PROPER_NOUN } from 'modules/i18n/properNouns'
import { SegmentEvents } from 'modules/segment'
import { trackDocShareLinkCopiedEvent } from 'modules/segment/helper'
import { getPublicAccessTooltipLabel } from 'modules/sharing/constants'
import {
  EventBusEvent,
  TiptapEventBus as tiptapEventEmitter,
} from 'modules/tiptap_editor/eventBus'
import { generateDocUrl, generateEmbedCode } from 'utils/url'

import { EmbedCodeCopier } from './EmbedCodeCopier'
import {
  NoAccessPermission,
  PermissionMapToHumanReadable,
} from './PermissionsMenu'
import { PermissionsSettingsRow } from './PermissionsSettingsRow'
import { PermissionsSettingsSection } from './PermissionsSettingsSection'
import { PermissionsSubtitleWithIcon } from './PermissionsSubtitleWithIcon'
import { RemoveWatermarkUpsell } from './RemoveWatermark'
import { SharePanelRowIcon } from './SharePanelRowIcon'

export const SharePublicOrEmbed = ({
  doc,
  isSharePublic,
  isConnected,
  onSharePanelOpen,
  onSharePanelClose,
  editor,
}: {
  doc: Doc
  isSharePublic: boolean
  isConnected: boolean
  onSharePanelOpen?: () => void
  onSharePanelClose: () => void
  editor?: Editor
}) => {
  const toast = useToast()
  const [isChecked, setIsChecked] = useState(!!doc.publicAccess)
  const [updateDocPublicAccessMutation] = useUpdateDocPublicAccessMutation()

  const publicUrlOrEmbedCode = useMemo(() => {
    const publicUrl = generateDocUrl({
      docId: doc.id,
      docTitle: doc.title,
      path: 'public',
      absolute: true,
    })
    if (isSharePublic) {
      return publicUrl
    } else {
      return generateEmbedCode({
        docId: doc.id,
        docTitle: doc.title,
      })
    }
  }, [doc.id, doc.title, isSharePublic])

  const onPermissionSettingChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.target.checked)

      const variables = {
        id: doc.id,
        publicAccess: e.target.checked ? Permission.View : null,
      } as const
      updateDocPublicAccessMutation({
        // Maybe<Doc> doesnt allow null, but the API does. Need to fix codegen (#422)
        // @ts-ignore
        variables,
        optimisticResponse: {
          // @ts-ignore
          updateDoc: { ...variables, __typename: 'Doc' },
        },
      }).then(() => {
        const title = e.target.checked
          ? t`Public access enabled`
          : t`Public access removed`
        toast({
          title,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        })
      })
    },
    [doc.id, toast, updateDocPublicAccessMutation]
  )

  const disabledText = doc.publicAccess
    ? PermissionMapToHumanReadable[Permission.View].title()
    : PermissionMapToHumanReadable[NoAccessPermission.NoAccess].title()

  const description = isSharePublic ? (
    <Trans>
      Let anyone with a link view a{' '}
      <Text
        as="span"
        borderBottom="0.125em dashed"
        borderColor="gray.400"
        cursor="help"
      >
        <GammaTooltip
          placement="top"
          label={t`They won't be able to see other people active in this ${GAMMA_ARTIFACT_PROPER_NOUN}, or follow along if you're presenting.`}
          aria-label="public access is read only"
        >
          read-only
        </GammaTooltip>{' '}
      </Text>
      version of this {GAMMA_ARTIFACT_PROPER_NOUN}.
    </Trans>
  ) : (
    <Trans>
      Enable public access to embed a copy of this {GAMMA_ARTIFACT_PROPER_NOUN}{' '}
      anywhere online.
    </Trans>
  )

  return (
    <>
      <Text fontSize="sm" color="gray.600" pt={2}>
        {description}
      </Text>

      <PermissionsSettingsSection showDivider={false}>
        <PermissionsSettingsRow
          title={<Trans>Public access</Trans>}
          testId="public-access"
          subtitle={
            <PermissionsSubtitleWithIcon
              subtitle={<Trans>Anyone with a link can view</Trans>}
              tooltipLabel={getPublicAccessTooltipLabel()}
              ariaLabel={t`Anyone with a link can view`}
              icon={regular('circle-info')}
            />
          }
          img={<SharePanelRowIcon icon={regular('globe-americas')} />}
          isDisabled={!isConnected}
          disabledText={disabledText}
          permissionsControl={
            <FormControl>
              <Switch
                id="public-access"
                isChecked={isChecked}
                onChange={onPermissionSettingChange}
              />
            </FormControl>
          }
        />
      </PermissionsSettingsSection>

      <GammaTooltip
        label={
          isSharePublic ? (
            <Trans>Enable public access to copy the public share link.</Trans>
          ) : (
            <Trans>Enable public access to get the embed code.</Trans>
          )
        }
        placement="top"
        isDisabled={isChecked}
      >
        <Box>
          {isSharePublic ? (
            <LinkCopier
              isDisabled={!doc.publicAccess}
              url={publicUrlOrEmbedCode}
              customLabel={t`Copy public link`}
              onClick={() => {
                trackDocShareLinkCopiedEvent({
                  docId: doc.id,
                  source: 'sharePanel',
                  type: 'public',
                })
              }}
            />
          ) : (
            <EmbedCodeCopier
              embedCode={publicUrlOrEmbedCode}
              isDisabled={!isChecked}
              docId={doc.id}
            />
          )}
        </Box>
      </GammaTooltip>
      {isSharePublic && editor && (
        <Text fontSize="sm" color="gray.500" mt={4}>
          <Trans>
            Tip: you can control how this looks in social media and search
            results in{' '}
            <Button
              variant="link"
              as="span"
              size="sm"
              cursor="pointer"
              onClick={() => {
                onSharePanelClose()
                tiptapEventEmitter.emit(
                  EventBusEvent.OPEN_PAGE_SETUP,
                  'preview'
                )
              }}
            >
              preview settings
            </Button>
            .
          </Trans>
        </Text>
      )}
      <RemoveWatermarkUpsell
        onClose={onSharePanelClose}
        onOpen={onSharePanelOpen}
        segmentEvent={
          SegmentEvents.GAMMA_PRO_UPSELL_SHARE_SETTINGS_REMOVE_WATERMARK
        }
        doc={doc}
      />
    </>
  )
}
