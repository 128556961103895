import { useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'

import { SharePanelViews } from 'modules/sharing/components/SharePanel'

export const useToggleSharePanel = () => {
  const {
    isOpen: isSharePanelOpen,
    onOpen: onSharePanelOpen,
    onClose: onSharePanelClose,
  } = useDisclosure({ id: 'sharePanelDisclosure' })
  const [view, setView] = useState<SharePanelViews>('invite')
  return {
    isSharePanelOpen,
    onSharePanelOpen,
    onSharePanelClose,
    view,
    setView,
  }
}
