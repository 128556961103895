import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t, Trans } from '@lingui/macro'
import { useState } from 'react'

import { useInviteMembersMutation } from 'modules/api'
import { GAMMA_ARTIFACT_PROPER_NOUN_PLURAL } from 'modules/i18n/properNouns'

type WorkspaceInviteForGuestsProps = {
  email?: string
  name?: string
  workspaceName?: string
  workspaceId: string
}

export const WorkspaceInviteForGuests = ({
  email,
  name,
  workspaceName,
  workspaceId,
}: WorkspaceInviteForGuestsProps) => {
  const [inviteMembers] = useInviteMembersMutation()
  const [hasClickedInvite, setHasClickedInvite] = useState(false)
  const toast = useToast()
  return (
    <Popover closeOnBlur={true}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              leftIcon={<FontAwesomeIcon icon={regular('passport')} />}
              size="xs"
              colorScheme="gray"
              mr={1}
              px={2}
              h="auto"
              py={1}
            >
              <Trans>Guest</Trans>
            </Button>
          </PopoverTrigger>
          <PopoverContent bg="white">
            <PopoverArrow />
            <PopoverHeader fontWeight="bold">
              <Trans>Invite guest to workspace?</Trans>
            </PopoverHeader>
            <PopoverBody>
              <Text fontSize="sm">
                <Trans>
                  {name} is not a member of the {workspaceName} workspace. If
                  you invite them to this workspace, they’ll have access to{' '}
                  {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL} shared with workspace
                  members.
                </Trans>
              </Text>
              <Button
                isDisabled={hasClickedInvite}
                w="100%"
                mt={2}
                onClick={() => {
                  if (!workspaceId || !email) return
                  setHasClickedInvite(true)
                  const variables = {
                    workspaceId,
                    invitees: [{ email }],
                  }
                  inviteMembers({
                    variables,
                  })
                    .then(() => {
                      toast({
                        title: t`Invitation sent`,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top',
                      })
                    })
                    .catch((e) => {
                      console.error(e)
                      toast({
                        title: t`Error sending invitation: ${e.message}`,
                        status: 'error',
                        isClosable: true,
                        position: 'top',
                      })
                    })
                    .finally(() => {
                      setHasClickedInvite(false)
                      onClose()
                    })
                }}
              >
                <Trans>Invite to workspace</Trans>
              </Button>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
