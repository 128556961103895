import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spacer,
  Stack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DarkModeProvider } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { useCallback, useRef, useState } from 'react'

import { Doc, useHealthCheck } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { useModalDisclosure } from 'modules/modal_state/hooks/useModalDisclosure'
import { useCanUseSites } from 'modules/monetization'
import { getOrderedSharePanelOptions } from 'modules/sharing/constants'
import {
  getVisibility,
  getVisibilityMap,
  isOtherOrgDoc,
} from 'modules/sharing/utils'
import { CopyToSiteInner } from 'modules/sites/components_v2/CopyToSiteInner'
import { CreateSiteModal } from 'modules/sites/components_v2/CreateSiteModal'
import { useCan, useUserContext } from 'modules/user'
import { isMobileDevice } from 'utils/deviceDetection'

import { Export } from './Export'
import { InviteOthers } from './InviteOthers'
import { ShareIcon } from './ShareIcon'
import { SharePublicOrEmbed } from './SharePublicOrEmbed'

export type SharePanelEventPayload = {
  view: SharePanelViews
  options?: {
    hideAnalytics?: boolean
  }
}
export type SharePanelViews =
  | 'invite'
  | 'public'
  | 'export'
  | 'embed'
  | 'website'

type SharePanelModalProps = {
  doc?: Doc
  editor?: Editor
  isSharePanelOpen: boolean
  onSharePanelClose: () => void
  onSharePanelOpen: () => void
  onAnalyticsPanelOpen?: () => void
  view: SharePanelViews
  setView: (view: SharePanelViews) => void
  showAnalytics?: boolean
  showSharePublic?: boolean
  showEmbed?: boolean
}

export const SharePanelModal = ({
  doc,
  editor,
  isSharePanelOpen,
  onSharePanelClose,
  onSharePanelOpen,
  onAnalyticsPanelOpen,
  view,
  setView,
  showAnalytics = true,
  showSharePublic = true,
  showEmbed = true,
}: SharePanelModalProps) => {
  const sitesEnabled = useCanUseSites()

  const orgIdErrorLogged = useRef(false)
  const { user, currentWorkspace } = useUserContext()
  const { isConnected } = useHealthCheck()
  const canManage = useCan('manage', doc)
  const canViewAnalytics = canManage && onAnalyticsPanelOpen
  const {
    isOpen: isCreateSiteModalOpen,
    onOpen: onCreateSiteModalOpen,
    onClose: onCreateSiteModalClose,
  } = useModalDisclosure({
    id: 'create-site-modal',
  })
  const SHARE_PANEL_OPTIONS = getOrderedSharePanelOptions({
    showSharePublic,
    showEmbed,
    showWebsites: sitesEnabled && !isMobileDevice(),
  })
  const [verifySearchDone, setVerifySearchDone] = useState<() => Promise<any>>(
    // Allows the CollaboratorSearchBar to register a done fn that we'll call before
    // closing the dialog, allowing it to interrupt closing if needed
    () => () => Promise.resolve()
  )

  const closeIfReady = useCallback(() => {
    verifySearchDone().then(
      () => {
        setView('invite')
        onSharePanelClose()
      },
      () => {
        console.debug(
          '[handleSharePanelClose] CollaboratorSearchBar rejected 👎🏼'
        )
      }
    )
  }, [setView, onSharePanelClose, verifySearchDone])

  const handleViewAnalyticsClick = useCallback(() => {
    if (onAnalyticsPanelOpen) {
      onSharePanelClose()
      onAnalyticsPanelOpen()
    }
  }, [onAnalyticsPanelOpen, onSharePanelClose])

  if (!user) return null
  const visibilityType = getVisibility(doc, user)
  const visibility = visibilityType ? getVisibilityMap()[visibilityType] : null
  const isFromOtherOrg = isOtherOrgDoc(doc, currentWorkspace)

  // We should never get this far, but err on the side of
  // caution by rendering nothing and logging an error
  if (doc && visibility === null) {
    console.error('[SharePanel] Unknown VisibilityValue for doc', doc)
    return null
  }
  const workspaceId = doc?.organization?.id
  if (doc && !workspaceId && !orgIdErrorLogged.current) {
    console.error(
      '[SharePanelModal] Doc unexpectedly doesnt have a workspaceId',
      {
        docId: doc.id,
        organization: doc.organization,
      }
    )
    orgIdErrorLogged.current = true
  }
  return (
    <DarkModeProvider isDark={false}>
      <Modal
        onEsc={closeIfReady}
        isOpen={isSharePanelOpen}
        onClose={onSharePanelClose}
        trapFocus={false} // This is needed to make the Entri iframe popup work
        size="3xl"
        returnFocusOnClose={false}
      >
        <ModalOverlay />
        <ModalContent data-share-panel-modal-content mx={2}>
          <ModalHeader>
            {visibility && (
              <Box as="span" mr={3}>
                <ShareIcon
                  visibility={visibility}
                  isFromOtherOrg={isFromOtherOrg}
                />
              </Box>
            )}
            {!doc ? <Trans>Share</Trans> : <Trans>Share {doc.title}</Trans>}
          </ModalHeader>
          <ModalCloseButton
            data-share-panel-close-button
            onClick={(e) => {
              e.preventDefault()
              closeIfReady()
            }}
          />
          <Divider />
          {!doc ? (
            <ModalBody pb={6} data-testid="share-panel-modal-body">
              <Stack>
                <Skeleton height="55px" />
                <Skeleton height="55px" />
                <Skeleton height="55px" />
                <Skeleton height="55px" />
              </Stack>
            </ModalBody>
          ) : (
            <ModalBody pb={6} data-testid="share-panel-modal-body">
              {doc && workspaceId && (
                <>
                  {canManage && (
                    <Wrap my={4}>
                      {SHARE_PANEL_OPTIONS.map((option) => {
                        const { viewKey, icon, ariaLabel, label, isBeta } =
                          option
                        const isSelected = view === viewKey
                        return (
                          <WrapItem key={viewKey}>
                            <Button
                              leftIcon={
                                <FontAwesomeIcon
                                  icon={icon}
                                  aria-label={ariaLabel}
                                />
                              }
                              variant="ghost"
                              size="sm"
                              fontWeight="600"
                              bg={isSelected ? 'trueblue.50' : 'transparent'}
                              color={isSelected ? 'trueblue.500' : 'gray.600'}
                              onClick={() => setView(viewKey)}
                              rightIcon={
                                isBeta ? (
                                  <Badge colorScheme="green">
                                    <Trans>Beta</Trans>
                                  </Badge>
                                ) : undefined
                              }
                            >
                              {label}
                            </Button>
                          </WrapItem>
                        )
                      })}
                    </Wrap>
                  )}

                  {(!canManage || view == 'invite') && (
                    <InviteOthers
                      workspaceId={workspaceId}
                      doc={doc}
                      user={user}
                      setVerifySearchDone={setVerifySearchDone}
                      canManage={canManage}
                      isFromOtherOrg={isFromOtherOrg}
                    />
                  )}
                  {canManage && (view === 'public' || view === 'embed') && (
                    <SharePublicOrEmbed
                      doc={doc}
                      isSharePublic={view == 'public'}
                      isConnected={isConnected}
                      onSharePanelOpen={onSharePanelOpen}
                      onSharePanelClose={onSharePanelClose}
                      editor={editor}
                    />
                  )}
                  {canManage && view === 'export' && (
                    <Export
                      doc={doc}
                      editor={editor}
                      onSharePanelOpen={onSharePanelOpen}
                      onSharePanelClose={onSharePanelClose}
                    />
                  )}
                  {sitesEnabled && canManage && view === 'website' && (
                    <CopyToSiteInner
                      doc={doc}
                      onCreateSiteClick={onCreateSiteModalOpen}
                    />
                  )}
                </>
              )}
            </ModalBody>
          )}
          <Divider />
          <ModalFooter>
            <Flex justifyContent={'space-between'} flex={1}>
              {showAnalytics && canViewAnalytics && (
                <Button
                  isDisabled={!isConnected}
                  leftIcon={<FontAwesomeIcon icon={regular('chart-column')} />}
                  variant="ghost"
                  onClick={handleViewAnalyticsClick}
                >
                  <Trans>View analytics</Trans>
                </Button>
              )}
              <Spacer />
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  closeIfReady()
                }}
                variant="solid"
              >
                <Trans>Done</Trans>
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {doc?.id && sitesEnabled && canManage && isCreateSiteModalOpen && (
        <CreateSiteModal
          isOpen={isCreateSiteModalOpen}
          onClose={onCreateSiteModalClose}
          existingDoc={doc}
        />
      )}
    </DarkModeProvider>
  )
}
