import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Circle,
  CloseButton,
  Collapse,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import {
  duotone,
  regular,
  solid,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import isNil from 'lodash/isNil'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'

import { LinkCopier } from 'gamma_components/LinkCopier'
import {
  Doc,
  DocChannel,
  DocCollaborator,
  DocInvitation,
  Permission,
  useAddCollaboratorsMutation,
  useHealthCheck,
  useRemoveCollaboratorsMutation,
  useUpdateDocAccessLinksMutation,
  useUpdateDocOrgAccessMutation,
} from 'modules/api'
import { useLocalizedFunction } from 'modules/i18n/hooks/useLocalizedFunction'
import {
  GAMMA_ARTIFACT_PROPER_NOUN,
  GAMMA_ARTIFACT_PROPER_NOUN_PLURAL,
  GAMMA_PROPER_NOUN,
} from 'modules/i18n/properNouns'
import { OfflineInfoBox } from 'modules/offline'
import { trackDocShareLinkCopiedEvent } from 'modules/segment/helper'
import { getPublicAccessTooltipLabel } from 'modules/sharing/constants'
import { getHumanReadablePermissionSummary } from 'modules/sharing/utils'
import { GraphqlUser } from 'modules/user'
import { useHandleError } from 'utils/hooks'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { generateDocUrl } from 'utils/url'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { ChannelRow } from './ChannelRow'
import { ChannelSearchBar } from './ChannelSearchBar'
import { CollaboratorSearchBar } from './CollaboratorSearchBar'
import {
  NoAccessPermission,
  PermissionMapToHumanReadable,
  PermissionsMenu,
} from './PermissionsMenu'
import { PermissionsSettingsRow } from './PermissionsSettingsRow'
import { PermissionsSettingsSection } from './PermissionsSettingsSection'
import { PermissionsSubtitleWithIcon } from './PermissionsSubtitleWithIcon'
import { SharePanelRowIcon } from './SharePanelRowIcon'
import { WorkspaceInviteForGuests } from './WorkspaceInviteForGuests'

type IndividualCollaborator = DocCollaborator | DocInvitation

// Not displaying the workspace notice while we decide where this will go and/or if we still want this
const DOC_WORKSPACE_NOTICE_IS_ENABLED = false

const getAccessLinkMessages = () => ({
  view: {
    subtitle: t`Can view`,
    tooltip: getPublicAccessTooltipLabel(),
  },
  comment: {
    subtitle: t`Can view and comment`,
    tooltip: t`${getPublicAccessTooltipLabel()} If they sign in they'll be added as a collaborator with comment permission.`,
  },
  edit: {
    subtitle: t`Can view, comment and edit`,
    tooltip: t`${getPublicAccessTooltipLabel()} If they sign in they'll be added as a collaborator with edit permission.`,
  },
})

const collabNameSortFn = (
  a: IndividualCollaborator,
  b: IndividualCollaborator
) => {
  const nameA =
    a.__typename === 'DocCollaborator'
      ? a.user.displayName?.toUpperCase()
      : a.__typename === 'DocInvitation'
      ? a.email.toUpperCase()
      : ''
  const nameB =
    b.__typename === 'DocCollaborator'
      ? b.user.displayName?.toUpperCase()
      : b.__typename === 'DocInvitation'
      ? b.email.toUpperCase()
      : ''

  if (!nameB || (nameA && nameA < nameB)) {
    return -1
  }
  if (!nameA || (nameB && nameA > nameB)) {
    return 1
  }

  return 0
}

const managerThenNameSortFn = (
  a: IndividualCollaborator,
  b: IndividualCollaborator
) => {
  if (a.permission !== b.permission) {
    if (a.permission === 'manage') return -1
    if (b.permission === 'manage') return 1
  }
  return collabNameSortFn(a, b)
}

type InviteOthersProps = {
  workspaceId: string
  isFromOtherOrg: boolean
  doc: Doc
  user: GraphqlUser
  setVerifySearchDone: Dispatch<SetStateAction<() => Promise<any>>>
  canManage: boolean
}

export const InviteOthers = ({
  workspaceId,
  isFromOtherOrg,
  doc,
  user,
  setVerifySearchDone,
  canManage,
}: InviteOthersProps) => {
  const ACCESS_LINK_MESSAGES = useLocalizedFunction(getAccessLinkMessages)
  const toast = useToast()
  const [hasDismissedDocWorkspaceNotice, setHasDismissedDocWorkspaceNotice] =
    useLocalStorage<boolean | string>(
      USER_SETTINGS_CONSTANTS.hasDismissedDocWorkspaceNotice,
      false
    )
  const [isViewingHowInviteLinkWorks, setIsViewingHowInviteLinkWorks] =
    useState(false)
  const { isConnected } = useHealthCheck()
  const [addCollaborators, { error: addError }] = useAddCollaboratorsMutation()
  const [removeCollaborators, { error: removeError }] =
    useRemoveCollaboratorsMutation()

  useHandleError('Error updating collaborator.', addError)
  useHandleError('Error removing collaborator.', removeError)

  const [updateDocOrgAccessMutation] = useUpdateDocOrgAccessMutation()
  const [updateDocAccessLinksMutation] = useUpdateDocAccessLinksMutation()
  const firstAccessLink = doc?.accessLinks?.[0]
  const shareUrl = useMemo(
    () =>
      generateDocUrl({
        docTitle: doc?.title,
        docId: doc?.id,
        token: firstAccessLink?.token,
        absolute: true,
      }),
    [doc, firstAccessLink?.token]
  )

  const canManageAndConnected = canManage && isConnected
  const allowUpdatingChannels = !isFromOtherOrg
  const shouldShowDocWorkspaceNotice =
    allowUpdatingChannels &&
    Boolean(!hasDismissedDocWorkspaceNotice && doc && !doc.orgAccess)
  const isInChannelsButNoOrgAccess = Boolean(
    !doc?.orgAccess &&
      doc?.channels &&
      doc?.channels.length > 0 &&
      doc?.channels.every((c) => isNil(c.permission))
  )
  const noWorkspaceAccessAndIsPublic =
    doc?.orgAccess == null && doc?.publicAccess == Permission.View
  const permissionSummary = getHumanReadablePermissionSummary(doc)

  const collaborators = doc?.collaborators || []
  const invitations = doc?.invitations || []
  const managerCount = collaborators!.filter(
    (c) => c.permission === Permission.Manage
  ).length

  const individualCollabList = [...collaborators!, ...invitations!].sort(
    managerThenNameSortFn
  )
  const permissionChangedToastFn = (verb: 'updated' | 'removed') => () => {
    const title =
      verb === 'updated' ? t`Permission updated` : t`Permission removed`
    toast({
      title,
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
  }

  const selectedAccessLinkPermission = firstAccessLink?.permission
    ? firstAccessLink?.permission
    : NoAccessPermission.NoAccess
  const hasTurnedOffAccessLink =
    selectedAccessLinkPermission === NoAccessPermission.NoAccess
  const emptyAccessLinkToken = firstAccessLink?.token === ''
  const hasNonViewAccessLink =
    firstAccessLink && firstAccessLink.permission !== Permission.View
  const workspaceAccess = doc.orgAccess
    ? doc.orgAccess
    : NoAccessPermission.NoAccess
  const hasWorkspaceAccess = workspaceAccess !== NoAccessPermission.NoAccess

  return (
    <>
      {canManageAndConnected && (
        <CollaboratorSearchBar
          workspaceId={workspaceId}
          docId={doc.id}
          user={user}
          existingDocCollaborators={collaborators}
          existingInvitations={invitations}
          registerDoneFn={setVerifySearchDone}
          isDisabled={!canManage}
        />
      )}

      <OfflineInfoBox
        label={<Trans>Sharing will be available when you reconnect.</Trans>}
        isConnected={isConnected}
        mb={4}
      />

      <Text fontSize="sm" color="gray.600" pt={2}>
        {permissionSummary}
      </Text>

      <PermissionsSettingsSection>
        {DOC_WORKSPACE_NOTICE_IS_ENABLED && (
          <Collapse in={shouldShowDocWorkspaceNotice} unmountOnExit>
            <Stack
              spacing={2}
              bg="gray.100"
              p={6}
              position="relative"
              borderRadius="md"
            >
              {/* You can change the access and then it goes away. If you specifically click the X, you'll never see it again */}
              <CloseButton
                pos="absolute"
                right={3}
                top={3}
                onClick={() => {
                  setHasDismissedDocWorkspaceNotice(true)
                }}
              />
              <Heading textAlign="center" color="gray.600">
                <FontAwesomeIcon icon={duotone('folder-open')} />
              </Heading>
              <Text textAlign="center" fontWeight="700">
                <Trans>Add workspace access to use folders</Trans>
              </Text>
              <Text fontSize="sm" color="gray.700" textAlign="center">
                <Trans>
                  Once you've added workspace access, you can use folders to
                  organize and discover {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL}.
                </Trans>
              </Text>
            </Stack>
          </Collapse>
        )}

        <PermissionsSettingsRow
          testId="workspace"
          title={<Trans>Workspace members</Trans>}
          subtitle={
            hasWorkspaceAccess ? t`Everyone in ${doc?.organization?.name}` : ``
          }
          img={
            <SharePanelRowIcon
              icon={regular('building')}
              showDisabled={!hasWorkspaceAccess}
            />
          }
          isDisabled={!canManageAndConnected}
          disabledText={PermissionMapToHumanReadable[workspaceAccess].title()}
          infoIcon={
            noWorkspaceAccessAndIsPublic ? (
              <GammaTooltip
                label={
                  <Trans>
                    This {GAMMA_ARTIFACT_PROPER_NOUN} will not appear in your
                    workspace, but anyone with the link can view.
                  </Trans>
                }
                placement="top"
              >
                <Box color="yellow.500">
                  <FontAwesomeIcon icon={regular('circle-info')} />
                </Box>
              </GammaTooltip>
            ) : undefined
          }
          permissionsControl={
            <PermissionsMenu
              isDisabled={!canManageAndConnected}
              selected={workspaceAccess}
              options={[
                Permission.Manage,
                Permission.Edit,
                Permission.Comment,
                Permission.View,
                NoAccessPermission.NoAccess,
              ]}
              onClick={(option) => {
                const variables = {
                  id: doc.id,
                  orgAccess:
                    option === NoAccessPermission.NoAccess ? null : option,
                }
                updateDocOrgAccessMutation({
                  // Maybe<Doc> doesnt allow null, but the API does. Need to fix codegen (#422)
                  // @ts-ignore
                  variables,
                  optimisticResponse: {
                    // @ts-ignore
                    updateDoc: { ...variables, __typename: 'Doc' },
                  },
                  // Refetch any outstanding docs queries because changing orgAccess can impact which docs are returned
                  refetchQueries: ['GetDocs'],
                }).then(
                  permissionChangedToastFn(
                    option === NoAccessPermission.NoAccess
                      ? 'removed'
                      : 'updated'
                  )
                )
              }}
            />
          }
        />
        {allowUpdatingChannels &&
          doc.channels?.map((channel) => {
            return (
              <ChannelRow
                channel={channel}
                doc={doc}
                key={channel.id}
                isDisabled={!isConnected}
                showDisabledIcon={isInChannelsButNoOrgAccess}
              />
            )
          })}
        {Boolean(doc.orgAccess) &&
          allowUpdatingChannels &&
          !isInChannelsButNoOrgAccess && (
            <Box w="100%" mt={2}>
              <ChannelSearchBar
                existingChannels={doc.channels as DocChannel[]}
                workspaceId={workspaceId}
                docId={doc.id}
                isDisabled={!isConnected}
              />
            </Box>
          )}
        <Collapse in={allowUpdatingChannels && isInChannelsButNoOrgAccess}>
          <Alert status="warning">
            <AlertIcon />
            <Trans>
              Your {GAMMA_ARTIFACT_PROPER_NOUN} will be hidden from these
              folders until you provide workspace access
            </Trans>
          </Alert>
        </Collapse>
      </PermissionsSettingsSection>

      {individualCollabList && (
        <PermissionsSettingsSection showDivider={canManage}>
          {individualCollabList.map((c) => {
            // Disable changing the manage permission if there aren't multiple managers
            const hasMinimumManagers =
              c.permission === Permission.Manage && managerCount < 2
            const isDisabled = !canManageAndConnected || hasMinimumManagers
            const tooltipText = hasMinimumManagers
              ? t({
                  message: `At least 1 Full Access collaborator is required.`,
                  comment:
                    "'Full Access' refers to someone who has the highest level of permissions on a doc. Please use the same phrase to refer to 'Full Access' across the app.",
                })
              : undefined
            const disabledText =
              PermissionMapToHumanReadable[c.permission].title()

            if (c.__typename === 'DocCollaborator') {
              const u = c.user
              return (
                <PermissionsSettingsRow
                  key={u.id}
                  testId={u.email}
                  title={`${u.displayName} ${u.id === user.id ? t`(You)` : ''}`}
                  img={
                    <Avatar
                      name={u.displayName}
                      src={u.profileImageUrl}
                      size="sm"
                    />
                  }
                  subtitle={!isDisabled ? u.email : undefined}
                  isDisabled={isDisabled}
                  disabledText={disabledText}
                  tooltipText={tooltipText}
                  workspaceGuestInvite={
                    !isDisabled && c.guest ? (
                      <WorkspaceInviteForGuests
                        name={u.displayName}
                        workspaceName={doc.organization?.name}
                        workspaceId={workspaceId}
                        email={c.user.email}
                      />
                    ) : undefined
                  }
                  permissionsControl={
                    <PermissionsMenu
                      isDisabled={isDisabled}
                      selected={c.permission}
                      options={[
                        Permission.Manage,
                        Permission.Edit,
                        Permission.Comment,
                        Permission.View,
                      ]}
                      disabledOptions={
                        c.guest
                          ? [
                              {
                                permission: Permission.Manage,
                                reason: t({
                                  message: `Only workspace members can have Full Access permission.`,
                                  comment:
                                    "'Full Access' refers to someone who has the highest level of permissions on a doc. Please use the same phrase to refer to 'Full Access' across the app.",
                                }),
                              },
                            ]
                          : []
                      }
                      onClick={(option) => {
                        const newPermission = option as Permission
                        const variables = {
                          docId: doc.id,
                          collaborators: [
                            {
                              userId: u.id,
                              permission: newPermission,
                            },
                          ],
                        }
                        addCollaborators({
                          variables,
                          optimisticResponse: {
                            addCollaborators: {
                              id: variables.docId,
                              collaborators: collaborators!.map(
                                // Update this user's permission to the selected option
                                (collab) => ({
                                  ...collab,
                                  permission:
                                    collab.user.id === u.id
                                      ? newPermission
                                      : collab.permission,
                                })
                              ),
                              invitations,
                              __typename: 'Doc',
                            },
                          },
                        })
                          .then(permissionChangedToastFn('updated'))
                          .catch(() => {})
                      }}
                      onRemove={() => {
                        const variables = {
                          docId: doc.id,
                          collaborators: [{ userId: u.id }],
                        }
                        removeCollaborators({
                          variables,
                          optimisticResponse: {
                            removeCollaborators: {
                              id: variables.docId,
                              // Remove this user from the list of collaborators
                              collaborators: collaborators!.filter(
                                (collab) => collab.user.id !== u.id
                              ),
                              invitations,
                              __typename: 'Doc',
                            },
                          },
                        }).then(permissionChangedToastFn('removed'))
                      }}
                    />
                  }
                />
              )
            }
            if (c.__typename === 'DocInvitation') {
              return (
                <PermissionsSettingsRow
                  key={c.email}
                  testId={c.email}
                  title={c.email}
                  subtitle={t`Invitation Sent`}
                  img={
                    <Circle bg="trueblue.50" size={8}>
                      <FontAwesomeIcon icon={regular('envelope')} size="sm" />
                    </Circle>
                  }
                  isDisabled={isDisabled}
                  disabledText={disabledText}
                  permissionsControl={
                    <PermissionsMenu
                      selected={c.permission}
                      options={[
                        Permission.Manage,
                        Permission.Edit,
                        Permission.Comment,
                        Permission.View,
                      ]}
                      disabledOptions={[
                        {
                          permission: Permission.Manage,
                          reason: t({
                            message: `Only workspace members can have Full Access permission.`,
                            comment:
                              "'Full Access' refers to someone who has the highest level of permissions on a doc. Please use the same phrase to refer to 'Full Access' across the app.",
                          }),
                        },
                      ]}
                      onClick={(option) => {
                        const newPermission = option as Permission
                        const variables = {
                          docId: doc.id,
                          collaborators: [
                            {
                              email: c.email,
                              permission: newPermission,
                            },
                          ],
                        }
                        addCollaborators({
                          variables,
                          optimisticResponse: {
                            addCollaborators: {
                              id: variables.docId,
                              invitations: invitations!.map(
                                // Update this user's permission to the selected option
                                (invitation) => ({
                                  ...invitation,
                                  permission:
                                    invitation.email === c.email
                                      ? newPermission
                                      : invitation.permission,
                                })
                              ),
                              collaborators,
                              __typename: 'Doc',
                            },
                          },
                        })
                          .then(permissionChangedToastFn('updated'))
                          .catch(() => {
                            console.warn('Error updating permission')
                          })
                      }}
                      onRemove={() => {
                        const variables = {
                          docId: doc.id,
                          collaborators: [{ email: c.email }],
                        }
                        removeCollaborators({
                          variables,
                          optimisticResponse: {
                            removeCollaborators: {
                              id: variables.docId,
                              // Remove this user from the list of invitations
                              invitations: invitations!.filter(
                                (invitation) => invitation.email !== c.email
                              ),
                              collaborators,
                              __typename: 'Doc',
                            },
                          },
                        }).then(permissionChangedToastFn('removed'))
                      }}
                    />
                  }
                />
              )
            }
            return null
          })}
        </PermissionsSettingsSection>
      )}

      {canManage && (
        <PermissionsSettingsSection showDivider={false}>
          <PermissionsSettingsRow
            title={<Trans>Anyone with the link</Trans>}
            subtitle={
              hasTurnedOffAccessLink ? (
                t`Link sharing has been turned off`
              ) : (
                <PermissionsSubtitleWithIcon
                  subtitle={
                    ACCESS_LINK_MESSAGES[selectedAccessLinkPermission].subtitle
                  }
                  tooltipLabel={
                    ACCESS_LINK_MESSAGES[selectedAccessLinkPermission].tooltip
                  }
                  ariaLabel={
                    ACCESS_LINK_MESSAGES[selectedAccessLinkPermission].subtitle
                  }
                  icon={regular('circle-info')}
                />
              )
            }
            img={
              <SharePanelRowIcon
                icon={regular('link')}
                showDisabled={hasTurnedOffAccessLink}
              />
            }
            isDisabled={!canManageAndConnected}
            disabledText={PermissionMapToHumanReadable[
              selectedAccessLinkPermission
            ].title()}
            permissionsControl={
              <PermissionsMenu
                selected={selectedAccessLinkPermission}
                options={[
                  Permission.Edit,
                  Permission.Comment,
                  Permission.View,
                  NoAccessPermission.NoAccess,
                ]}
                onClick={(option) => {
                  const existingAccessLinkId = firstAccessLink?.id
                  const variables = {
                    id: doc.id,
                    accessLinks:
                      option === NoAccessPermission.NoAccess
                        ? [
                            {
                              permission: null,
                            },
                          ]
                        : [
                            {
                              permission: option,
                            },
                          ],
                  } as const
                  updateDocAccessLinksMutation({
                    // Maybe<Doc> doesnt allow null, but the API does. Need to fix codegen (#422)
                    // @ts-ignore
                    variables,
                    optimisticResponse: {
                      // @ts-ignore
                      updateDoc: {
                        ...variables,
                        accessLinks: [
                          // The API input only allows "permission", but we need the __typename and id so
                          // that the apollo cache can identify the entry
                          {
                            __typename: 'AccessLink',
                            id: existingAccessLinkId
                              ? existingAccessLinkId
                              : 'temp-id',
                            // @ts-ignore
                            permission:
                              option === NoAccessPermission.NoAccess
                                ? null
                                : option,
                          },
                        ],
                        __typename: 'Doc',
                      },
                    },
                  }).then(
                    permissionChangedToastFn(
                      option === NoAccessPermission.NoAccess
                        ? 'removed'
                        : 'updated'
                    )
                  )
                }}
              />
            }
          />
          <Collapse animateOpacity in={Boolean(firstAccessLink?.permission)}>
            {/* TODO: Show "Give the doc a title" prompt */}
            <LinkCopier
              url={shareUrl}
              customLabel={t`Copy link`}
              onClick={() => {
                trackDocShareLinkCopiedEvent({
                  docId: doc.id,
                  source: 'sharePanel',
                  type: 'tokenUrl',
                })
              }}
            />
            {!emptyAccessLinkToken && (
              <>
                <Collapse in={isViewingHowInviteLinkWorks} unmountOnExit>
                  <VStack
                    mt={3}
                    align="stretch"
                    spacing={4}
                    bg="gray.50"
                    p={6}
                    borderRadius="md"
                  >
                    <HStack spacing={4} align="center">
                      <Box color="purple.600">
                        <FontAwesomeIcon fixedWidth icon={solid('lock-open')} />
                      </Box>
                      <Text fontSize="sm">
                        <Trans>
                          This link contains a special token that acts like a
                          password.
                        </Trans>
                      </Text>
                    </HStack>
                    <HStack spacing={4} align="center">
                      <Box color="purple.600">
                        <FontAwesomeIcon fixedWidth icon={solid('eye')} />
                      </Box>
                      <Text fontSize="sm" display="inline-block">
                        <Trans>
                          Anyone with this link will be able to view this{' '}
                          {GAMMA_ARTIFACT_PROPER_NOUN} without a{' '}
                          {GAMMA_PROPER_NOUN} account.
                        </Trans>
                      </Text>
                    </HStack>
                    {hasNonViewAccessLink && (
                      <HStack spacing={4} align="center">
                        <Box color="purple.600">
                          <FontAwesomeIcon
                            fixedWidth
                            icon={solid('user-plus')}
                          />
                        </Box>
                        <Text fontSize="sm">
                          <Trans>
                            If they sign in or create a {GAMMA_PROPER_NOUN}{' '}
                            account, they'll be added as a collaborator with{' '}
                            <Text as="span" fontWeight="bold">
                              {selectedAccessLinkPermission}
                            </Text>{' '}
                            permission.
                          </Trans>
                        </Text>
                      </HStack>
                    )}
                  </VStack>
                </Collapse>
                <Flex justify="center" alignItems="center" my={3}>
                  <HStack
                    spacing={1}
                    as="button"
                    onClick={() =>
                      setIsViewingHowInviteLinkWorks(
                        !isViewingHowInviteLinkWorks
                      )
                    }
                  >
                    {isViewingHowInviteLinkWorks ? (
                      <FontAwesomeIcon
                        fixedWidth
                        size="xs"
                        icon={regular('angle-up')}
                      />
                    ) : (
                      <FontAwesomeIcon
                        fixedWidth
                        size="xs"
                        icon={regular('plus')}
                      />
                    )}
                    <Text
                      lineHeight="normal"
                      marginLeft="auto"
                      marginRight="auto"
                      fontSize="xs"
                      fontWeight="medium"
                    >
                      {isViewingHowInviteLinkWorks ? (
                        <Trans>Hide explanation</Trans>
                      ) : (
                        <Trans>How invite links work</Trans>
                      )}
                    </Text>
                  </HStack>
                </Flex>

                {/* We're adding this Slack app callout temporarily, while we wait for approval to be listed in their App store. */}
                {/* Actually disabling this since we have view-only share tokens on by default now */}
                {/* <HStack
                  background="teal.50"
                  fontSize="sm"
                  padding={3}
                  borderRadius="md"
                >
                  <FontAwesomeIcon icon={regular('message-smile')} />
                  <Text>
                    <Trans>
                      Give your {GAMMA_ARTIFACT_PROPER_NOUN} a nice Slack
                      preview with our{' '}
                      <Link
                        href="https://gamma.app/slack"
                        isExternal
                        fontWeight={600}
                        textDecoration="underline"
                      >
                        Slack app
                      </Link>
                      .
                    </Trans>
                  </Text>
                </HStack> */}
              </>
            )}
          </Collapse>
        </PermissionsSettingsSection>
      )}
    </>
  )
}
