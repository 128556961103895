import { Badge, HStack, Spacer, Stack, Switch, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { Doc } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags/hooks/useFeatureFlag'
import {
  GAMMA_PROPER_NOUN,
  PLUS_PROPER_NOUN,
  PRO_PROPER_NOUN,
} from 'modules/i18n/properNouns'
import { useCanHideMadeWithGammaBadge } from 'modules/monetization'
import { useMonetizationContext } from 'modules/monetization/context'
import { SegmentEvents } from 'modules/segment'

export const RemoveWatermarkUpsell = ({
  onClose,
  onOpen,
  segmentEvent,
  doc,
  label,
}: {
  onOpen?: () => void
  onClose?: () => void
  segmentEvent: SegmentEvents
  doc?: Doc
  label?: React.ReactElement
}) => {
  const upsellUXEnabled_Pro = useFeatureFlag('upsellUXEnabled_Pro')
  const canHideMadeWithGammaBadge = useCanHideMadeWithGammaBadge(
    doc?.organization
  )
  const { openUpsellModal } = useMonetizationContext()

  if (canHideMadeWithGammaBadge !== false || !upsellUXEnabled_Pro) {
    return null
  }

  return (
    <Stack my={4} borderTop="1px solid var(--chakra-colors-gray-100)" w="100%">
      <HStack mt={4}>
        <HStack>
          <Text>
            {label || (
              <Trans>Remove "Made with {GAMMA_PROPER_NOUN}" badge</Trans>
            )}
          </Text>
          <Badge colorScheme="trueblue" variant="solid">
            {PLUS_PROPER_NOUN}
          </Badge>
        </HStack>
        <Spacer />
        <Switch
          onClickCapture={(e) => {
            e.preventDefault()
            openUpsellModal({
              onOpenCallback: onClose,
              onCloseCallback: onOpen,
              segmentEvent,
            })
          }}
          checked={false}
        />
      </HStack>
      <Text fontSize="xs" color="gray.600">
        <Trans>
          Upgrade to share your work without any {GAMMA_PROPER_NOUN} branding.
        </Trans>
      </Text>
    </Stack>
  )
}
